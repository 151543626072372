<template>
  <ul class="nav nav-tabs tabs-bordered">
    <li class="nav-item">
      <a
        href="#profile"
        data-toggle="tab"
        aria-expanded="true"
        class="nav-link active"
        >{{ trans("users.tabMenuLabelProfile") }}</a
      >
    </li>
    <li class="nav-item">
      <a
        href="#settings"
        data-toggle="tab"
        aria-expanded="false"
        class="nav-link"
        :class="componentPurpose === 'admin' ? 'settingsLink' : ''"
        >{{ trans("users.tabMenuLabelSettings") }}</a
      >
    </li>
    <li class="nav-item">
      <a
        href="#products"
        data-toggle="tab"
        aria-expanded="false"
        class="nav-link"
        >{{ trans("users.tabMenuLabelProducts") }}</a
      >
    </li>
    <li v-if="componentPurpose === 'userSettings'" class="nav-item">
      <a
        href="#twofa"
        data-toggle="tab"
        aria-expanded="false"
        class="nav-link"
        >{{ trans("users.tabMenuLabelTwoFactorAuthentication") }}</a
      >
    </li>
    <li v-if="componentPurpose === 'userSettings'" class="nav-item">
      <a
        href="#history"
        data-toggle="tab"
        aria-expanded="false"
        class="nav-link"
        >{{ trans("users.historicalHeader") }}</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    componentPurpose: String,
  },
};
</script>
